
import { computed, defineComponent, ref } from 'vue'
import { getTableData } from '@/services/tableService'
import type { SenderCountries } from '@/definitions/services/countries/types'
import type { TableHeaders } from '@/definitions/shared/types'
import { filterItemsByValues } from '@/services/utils'
import CountriesTable from '@/components/pages/services/countries/countries/CountriesTable.vue'
import TmButton from '@/components/shared/TmButton.vue'
import CountriesFilter from '@/components/pages/services/countries/countries/CountriesFilter.vue'

export default defineComponent({
  name: 'ServicesCountries',
  components: {
    CountriesTable,
    TmButton,
    CountriesFilter,
  },
  setup() {
    const showOtherCountries = ref(false)
    const search = ref('')

    const [mock] = getTableData('servicesCountries')
    const myCountry: SenderCountries[] = mock.myCountry
    const popularCountries: SenderCountries[] = mock.popularCountries
    const otherCountries: SenderCountries[] = mock.otherCountries
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Destination', value: 'destination-slot', width: '300px' },
      { text: 'Default sender', value: 'defaultSender' },
      { text: 'Type', value: 'type-slot' },
      { text: '', value: 'buy-number-slot', hideSortable: true },
      { text: '', value: 'edit', hideSortable: true },
    ])
    const filterTable = (item: SenderCountries) => (
      filterItemsByValues(search.value, [item.type.name, item.defaultSender, item.destination.name])
    )
    const filteredMyCountry = computed<SenderCountries[]>(() => myCountry.filter(filterTable))
    const filteredPopularCountries = computed<SenderCountries[]>(() => popularCountries.filter(filterTable))
    const filteredOtherCountries = computed<SenderCountries[]>(() => otherCountries.filter(filterTable))

    return {
      showOtherCountries,
      search,
      filteredMyCountry,
      filteredPopularCountries,
      filteredOtherCountries,
      tableHeaders,
    }
  },
})
